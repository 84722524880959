import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Link from 'next/link';

const styles = theme => ({
  root: {
    backgroundColor: '#E8F3FD',
    padding: '12px 0px 0 0px',
  },
  topContainer: {
    display: 'flex',
    marginTop: 28,
    marginBottom: 20,
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  link: {
    fontSize: 14,
    color: '#282D3C',
    marginBottom: 23,
    marginRight: 20,
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      marginRight: 44,
    },
  },
  topLeftWrapper: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flex: 2,
      alignItems: 'center',
      flexDirection: 'row',
    },
  },
  logo: {
    width: 150,
    marginBottom: 25,
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      width: 'auto',
    },
  },
  socialWrapper: {
    display: 'flex',
    marginBottom: 18,
    marginTop: 35,
  },
  socialLink: { marginRight: 9.5 },
  email: {
    fontSize: 16,
    color: '#076BD1',
  },
  bottomContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    padding: '23px 20px',
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  copy: {
    color: '#282D3C',
  },
  links: {
    marginTop: 12,
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
  },
});

const Footer = ({ classes }) => (
  <div className={classes.root}>
    <div className="container">
      <div className={classes.topContainer}>
        <div className={classes.topLeftWrapper}>
          <Link href="/">
            <a>
              <img src="/static/images/logo.svg" className={classes.logo} />
            </a>
          </Link>
        </div>
        <div className={classes.topRightWrapper}>
          <div className={classes.socialWrapper}>
            <a
              target="_blank"
              href="https://www.facebook.com/sellonlivetech"
              className={classes.socialLink}
              rel="noreferrer"
            >
              <img src="/static/images/facebookIcon.svg" />
            </a>
            {/* <a href="/" className={classes.socialLink}>
              <img src="/static/images/instagramIcon.svg" />
            </a> */}
          </div>
          <a href="mailto:sellonlivetech@gmail.com" className={classes.email}>
            sellonlivetech@gmail.com
          </a>
        </div>
      </div>
      <div className={classes.bottomContainer}>
        <Typography>&copy;2021 SellOnLive Tech (003155290-H) All Rights Reserved</Typography>
        <div className={classes.links}>
          <Link href="/about-us">
            <a className={classes.link}>About Us</a>
          </Link>
          <Link href="/privacy-policy">
            <a className={classes.link}>Privacy Policy</a>
          </Link>
          <Link href="/terms-of-service">
            <a className={classes.link}>Terms of Service</a>
          </Link>
          <Link href="/merchant-payment-and-refunds">
            <a className={classes.link}>Refund Policy</a>
          </Link>
        </div>
      </div>
    </div>
  </div>
);

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
