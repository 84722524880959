import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: ({ mobileBgImage }) => `url(${mobileBgImage})`,
    height: 380,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      backgroundImage: ({ desktopBgImage }) => `url(${desktopBgImage})`,
    },
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
  contentWrapper: {
    width: 450,
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      width: 600,
      margin: 'initial',
    },
  },
  heading: {
    fontSize: 34,
    marginBottom: 22,
    lineHeight: '40px',
    color: 'black',
    [theme.breakpoints.up('md')]: {
      marginBottom: 14,
      fontSize: 48,
      lineHeight: '57px',
    },
  },
  description: {
    fontSize: 16,
    lineHeight: '25px',
    marginBottom: 32,
    fontFamily: 'CircularStd-Book',
    [theme.breakpoints.up('md')]: {
      marginBottom: 47,
      fontSize: 28,
      lineHeight: '42px',
    },
  },
  registerButton: {
    backgroundColor: '#076BD1',
    borderRadius: 40,
    height: 38,
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 90,
      paddingRight: 90,
      height: 55,
    },
  },
  registerLabel: {
    color: '#fff',
    padding: '12px 36px',
    fontSize: 16,
    lineHeight: '19px',
    position: 'relative',
    top: 10,
    [theme.breakpoints.up('md')]: {
      top: 14,
      fontSize: 20,
      lineHeight: '24px',
    },
  },
}));

const PlanBanner = ({
  heading = 'plan-banner-heading',
  description = 'plan-banner-desc',
  remarks,
  mobileBgImage = '/static/images/pricing-1-mobile.jpg',
  desktopBgImage = '/static/images/pricing-1.jpg',
  url = '/pricing',
}) => {
  const classes = useStyles({ mobileBgImage, desktopBgImage });
  const { t } = useTranslation('common');
  return (
    <div className={classes.root}>
      <div className={['container', classes.contentContainer].join(' ')}>
        <div className={classes.contentWrapper}>
          <Typography
            variant="h1"
            component="h2"
            className={classes.heading}
            dangerouslySetInnerHTML={{ __html: t(heading) }}
          ></Typography>
          <Typography
            variant="h2"
            className={classes.description}
            dangerouslySetInnerHTML={{ __html: t(description) }}
          ></Typography>
          <Link href={url}>
            <a className={classes.registerButton}>
              <span className={classes.registerLabel}>{t('plan-banner-learn-more-btn')}</span>
            </a>
          </Link>
          {remarks ? <p>{t(remarks)}</p> : null}
        </div>
      </div>
    </div>
  );
};

export default PlanBanner;
