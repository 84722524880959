import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useRouter, withRouter } from 'next/router';
import Link from 'next/link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'next-i18next';
import cookie from 'js-cookie';
import _get from 'lodash/get';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import cx from 'classnames';

const styles = theme => ({
  root: {
    width: '100%',
    zIndex: 100,
    backgroundColor: '#fff',
    position: 'fixed',
    top: 0,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 58,
    alignItems: 'center',
  },
  logo: {
    width: 130,
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      height: 41,
    },
  },
  registerButton: {
    backgroundColor: '#076BD1',
    borderRadius: 20,
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 22px',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  registerLabel: {
    color: '#fff',
    whiteSpace: 'nowrap',
  },
  loginButton: {
    backgroundColor: '#E1F2FF',
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 22px',
    [theme.breakpoints.up('sm')]: {
      marginRight: 19,
    },
  },
  loginLabel: {
    color: '#282D3C',
    paddingLeft: 8,
    whiteSpace: 'nowrap',
  },
  rightContentWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  switchLanguageWrapper: {
    display: 'none',
    padding: '0 15px',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  affiliateLink: {
    display: 'none',
    color: '#31415E',
    [theme.breakpoints.up('sm')]: {
      backgroundColor: '#E1F2FF',
      borderRadius: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '8px 22px',
      whiteSpace: 'nowrap',
    },
  },
  menuIconWrapper: {
    display: 'block',
    paddingLeft: 20,
    paddingTop: 5,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  mobileMenuList: {
    display: 'none',
    borderBottom: '1px solid #ddd',
    '-webkit-box-shadow': '0px 8px 6px 0px rgba(41,41,41,.55)',
    '-moz-box-shadow': '0px 8px 6px 0px rgba(41,41,41,.55)',
    'box-shadow': '0px 8px 6px 0px rgba(41,41,41,.55)',
  },
  mobileMenuItem: {
    borderTop: '1px solid #ddd',
    padding: '15px 20px',
  },
  mobileMenuListVisible: {
    display: 'block',
  },
  leftContentWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  leftMenuList: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 40,
      display: 'block',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: 80,
      display: 'block',
    },
  },
});

const Header = ({ classes }) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isShowMobileMenu, setIsShowMobileMenu] = React.useState(false);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const switchLocale = lang => {
    cookie.set('language', lang, {
      path: '/',
      domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
    });
    window.location.href =
      lang === 'en' ? _get(router, 'asPath', '/') : `/${lang}${_get(router, 'asPath', '/')}`;
  };
  return (
    <div className={classes.root}>
      <div className="container">
        <div className={classes.wrapper}>
          <div className={classes.leftContentWrapper}>
            <Link href="/">
              <a>
                <img src="/static/images/logo.svg" className={classes.logo} />
              </a>
            </Link>
            <div className={classes.leftMenuList}>
              <Link href="/affiliate">
                <a className={classes.affiliateLink}>{t('become-affiliate')}</a>
              </Link>
            </div>
          </div>
          <div className={classes.rightContentWrapper}>
            <div className={classes.switchLanguageWrapper}>
              <span onClick={handleClick}>{t('change-language')}</span>
              <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={switchLocale.bind(this, 'en')}>English</MenuItem>
                <MenuItem onClick={switchLocale.bind(this, 'cn')} locale="cn">
                  中文
                </MenuItem>
              </Menu>
            </div>
            <a href="https://manage.sellonlive.tech/user/login" className={classes.loginButton}>
              <img src="/static/images/avatar.svg" />
              <span className={classes.loginLabel}>{t('login')}</span>
            </a>
            <a
              href="https://api.whatsapp.com/send/?phone=60162433083&text=你好，想知道有关抓单系统的详情。"
              className={classes.registerButton}
            >
              <span className={classes.registerLabel}>{t('request-for-an-account')}</span>
            </a>
            <div
              className={classes.menuIconWrapper}
              onClick={() => setIsShowMobileMenu(!isShowMobileMenu)}
            >
              {isShowMobileMenu ? <CloseIcon /> : <MenuIcon />}
            </div>
          </div>
        </div>
      </div>
      <div
        className={cx(classes.mobileMenuList, {
          [classes.mobileMenuListVisible]: isShowMobileMenu,
        })}
      >
        <div className={classes.mobileMenuItem}>
          <Link href="https://api.whatsapp.com/send/?phone=60162433083&text=你好，想知道有关抓单系统的详情。">
            <a onClick={() => setIsShowMobileMenu(false)}>{t('request-for-an-account')}</a>
          </Link>
        </div>
        <div className={classes.mobileMenuItem}>
          <Link href="/pricing">
            <a onClick={() => setIsShowMobileMenu(false)}>{t('plans-and-features')}</a>
          </Link>
        </div>
        <div className={classes.mobileMenuItem}>
          <Link href="/affiliate">
            <a onClick={() => setIsShowMobileMenu(false)}>{t('become-affiliate')}</a>
          </Link>
        </div>
        <div className={classes.mobileMenuItem}>
          <span onClick={handleClick}>{t('change-language')}</span>
          <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={switchLocale.bind(this, 'en')}>English</MenuItem>
            <MenuItem onClick={switchLocale.bind(this, 'cn')} locale="cn">
              中文
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Header));
