import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography, Button } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: ({ mobileBgImage }) => `url(${mobileBgImage})`,
    height: '100vh',
    maxHeight: 462,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      backgroundImage: ({ desktopBgImage }) => `url(${desktopBgImage})`,
      maxHeight: 720,
    },
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
  contentContainerAlignLeft: {
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
  contentWrapper: {
    width: 450,
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      width: 600,
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      margin: 'initial',
    },
  },
  heading: {
    fontSize: 34,
    marginBottom: 22,
    lineHeight: '40px',
    color: 'black',
    [theme.breakpoints.up('md')]: {
      marginBottom: 24,
      fontSize: ({ language }) => (language === 'cn' ? 65 : 56),
      lineHeight: ({ language }) => (language === 'cn' ? '83px' : '60px'),
    },
  },
  description: {
    fontSize: 16,
    lineHeight: '25px',
    marginBottom: 32,
    fontFamily: 'CircularStd-Book',
    [theme.breakpoints.up('md')]: {
      marginBottom: ({ language }) => (language === 'cn' ? 90 : 50),
      fontSize: ({ language }) => (language === 'cn' ? 30 : 28),
      lineHeight: ({ language }) => (language === 'cn' ? '42px' : '40px'),
    },
  },
  registerButton: {
    backgroundColor: '#076BD1',
    borderRadius: 40,
    height: 38,
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      height: 60,
    },
  },
  registerLabel: {
    color: '#fff',
    padding: '12px 36px',
    fontSize: 16,
    lineHeight: '19px',
    position: 'relative',
    top: 10,
    [theme.breakpoints.up('md')]: {
      top: 16,
      fontSize: 24,
      lineHeight: '28px',
    },
  },
}));

const LandingIntroduction = ({
  heading = 'landing-introduction-heading',
  subHeading = 'landing-introduction-desc',
  ctaLabel = 'landing-introduction-register-btn',
  align = 'right',
  mobileBgImage = '/static/images/landing-image-mobile.webp',
  desktopBgImage = '/static/images/landing-image.webp',
  url = 'https://api.whatsapp.com/send/?phone=60162433083&text=你好，想知道有关抓单系统的详情。',
}) => {
  const { t, i18n } = useTranslation('common');
  const classes = useStyles({ language: i18n.language, mobileBgImage, desktopBgImage });
  return (
    <div className={classes.root}>
      <div
        className={cx(['container', classes.contentContainer], {
          [classes.contentContainerAlignLeft]: align === 'left',
        })}
      >
        <div className={classes.contentWrapper}>
          <Typography
            variant="h1"
            component="h2"
            className={classes.heading}
            dangerouslySetInnerHTML={{ __html: t(heading) }}
          ></Typography>
          <Typography variant="h2" className={classes.description}>
            {t(subHeading)}
          </Typography>
          <a href={url} className={classes.registerButton}>
            <span className={classes.registerLabel}>{t(ctaLabel)}</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default LandingIntroduction;
