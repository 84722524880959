import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'next-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#E8F3FD',
    paddingTop: 40,
    paddingBottom: 50,
    [theme.breakpoints.up('md')]: {
      paddingTop: 80,
      paddingBottom: 80,
    },
  },
  heading: {
    textAlign: 'center',
    fontSize: 34,
    color: 'black',
    [theme.breakpoints.up('md')]: {
      fontSize: 48,
    },
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 44,
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      marginBottom: 58,
    },
  },
  featuresWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridRowGap: 30,
    gridColumnGap: 30,
    [theme.breakpoints.up('sm')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridRowGap: 40,
      gridColumnGap: 55,
    },
    [theme.breakpoints.up('lg')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridRowGap: 60,
      gridColumnGap: 115,
    },
  },
  featureItem: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
  },
  featureItemImageWrapper: {
    marginRight: 29,
    [theme.breakpoints.up('md')]: {
      marginRight: 30,
    },
  },
  featureItemImage: {
    height: 'auto',
    width: 80,
    [theme.breakpoints.up('lg')]: {
      width: 100,
    },
  },
  featureItemHeading: {
    fontSize: 16,
    marginBottom: 11,
    color: '#000',
    lineHeight: '20px',
    [theme.breakpoints.up('md')]: {
      marginBottom: 8,
      fontSize: 20,
      lineHeight: '25px',
    },
  },
  featureItemDescription: {
    fontSize: 12,
    fontFamily: 'CircularStd-Book',
    color: '#31415E',
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
    },
  },
}));

const FeatureItem = ({ image, heading, description, classes }) => {
  return (
    <div className={classes.featureItem}>
      <div className={classes.featureItemImageWrapper}>
        <img className={classes.featureItemImage} src={image} />
      </div>
      <div className={classes.featureItemContentWrapper}>
        <Typography className={classes.featureItemHeading}>
          <b>{heading}</b>
        </Typography>
        <Typography className={classes.featureItemDescription}>{description}</Typography>
      </div>
    </div>
  );
};

const Features = ({ heading = 'subfeatures-heading', features = [], alignItems = 'initial' }) => {
  const classes = useStyles({ alignItems });
  const { t } = useTranslation('common');

  return (
    <div className={classes.root}>
      <div className={classes.item}>
        <div className="container">
          <div className={classes.contentWrapper}>
            <Typography variant="h2" className={classes.heading}>
              {t(heading)}
            </Typography>
          </div>
          <div className={classes.featuresWrapper}>
            {features.map(({ image, heading, description }, i) => (
              <FeatureItem
                key={i}
                classes={classes}
                image={image}
                heading={t(heading)}
                description={t(description)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
